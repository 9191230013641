import React from 'react';
import { Modal } from 'react-bootstrap';
import UnierosSouthAfrica from '../assets/images/events/south_africa_world_cup_viewing.jpg';
import UnierosLivingGreen from '../assets/images/events/living_green_cause.jpg';
import UnierosRootsRock from '../assets/images/events/roots_rock_reggae_unieros.jpg';

 /* Component for displaying past Unieros events.
 *
 * @component
 * @returns {JSX.Element} The EventArchive component.
 */
const EventArchive = () => {

  const archiveEventsData = [
    {
        title: 'Jamaica 60th Independence Celebration in Savannah, Georgia',
        image: 'https://unieros.com/global/wp-content/uploads/2022/08/Saurico06-scaled.jpg',
        videoUrl: 'https://www.youtube.com/embed/B-bufubLFH0?si=ZNyh-VHS29-V4sT0',
      },
    {
      title: 'Bastille Day French Picnic in Savannah, Georgia',
      image: 'https://unieros.com/global/wp-content/uploads/2022/08/Unieros_bastille_day_picnic_logo-scaled.jpg',
    },
    {
      title: 'Unieros Roots Rock Reggae Concert in Chicago',
      image: UnierosRootsRock,
    },
    {
      title: 'Unieros Living Green for a Cause, Charity event in Chicago',
      image: UnierosLivingGreen,
    },
    {
        title: 'Unieros World Cup Party in Chicago',
        image: UnierosSouthAfrica,
      },
      {
        title: 'Unieros Fashion Show, in Chicago',
        image: 'https://unieros.com/global/wp-content/uploads/2016/06/FashionShowLien.jpg',
      },
  ];

  return (
    <div className="past-events">
      <h2 className="text-center mb-4">Past Unieros Events</h2>
      <div className="row">
        {archiveEventsData.map((event, index) => (
          <div key={index} className="col-md-4">
            <div className="card event-card">
              <img src={event.image} className="card-img-top" alt={event.title} />
              <div className="card-body">
                <h5 className="card-title event-title">{event.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Add a modal to display the video when the user clicks on the event card */}
      <Modal>
        <Modal.Header closeButton>
          <Modal.Title>Event Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="315"
            src={archiveEventsData[0].videoUrl}
            title="Event Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
   
        };


export default EventArchive;
